<template>
  <div>
    <v-card flat width="400">
      <p class="text-justify text-h5 font-weight-light">
        Welcome to the registration platform for the 2024 PrimeCare Managers
        Teaching Symposium
      </p>
      <v-card-text>
        <v-btn block color="primary" to="/register">Free Registration </v-btn>
        <v-btn block color="primary" class="mt-5" @click="handleLoginBtnClick"
          >Login
        </v-btn>
      </v-card-text>
    </v-card>
    <v-dialog v-model="loginMessagePopup" max-width="350">
      <v-card>
        <v-card-text
          class="text--blue-grey darken-4 text-justify login_message_popup_text pt-3 pb-2"
        >
          The 2023 Best Practices Symposium has not yet begun, please return to
          join us on May 10. See you then!
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            rounded
            text
            dark
            @click="loginMessagePopup = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Landing",
  data() {
    return {
      form_error: {},
      email: null,
      password: null,
      loading: false,
      data: {},
      loginMessagePopup: false,
    };
  },
  methods: {
    handleLoginBtnClick() {
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>

<style scoped>
.login_message_popup_text {
  font-size: 15px !important;
  line-height: 26px;
}
</style>
